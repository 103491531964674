import React from 'react';
import { Logo } from './Logo';

export default function Header() {
  return (
    <header className="container header">
      <div className="row">
        <div className="col-xs-12 col-lg-10 col-lg-offset-1">
          <a href="https://thehalcyonmovement.org/" className="logo">
            <Logo />
          </a>
        </div>
      </div>
    </header>
  );
}
