import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  loading: boolean;
  text?: React.ReactNode;
  disabled?: boolean;
}

export default function FormSubmitButton({ loading, text, disabled }: Props) {
  return (
    <button
      type="submit"
      className="button"
      disabled={loading || disabled}
      style={{
        position: 'relative',
        color: loading ? 'transparent' : '',
      }}
    >
      {text || 'Submit'}
      {loading && (
        <motion.div
          initial={{ '--rotate': '0deg' } as any}
          animate={{ '--rotate': '360deg' } as any}
          transition={{ duration: 1, repeat: Infinity }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '2.25rem',
              width: '2.25rem',
              transform: 'translate(-50%, -50%) rotate(var(--rotate))',
            }}
          >
            <path
              fill="#fff"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            />
          </svg>
        </motion.div>
      )}
    </button>
  );
}
