import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { motion } from 'framer-motion';
import { cityStates } from '../../utils/cityStates';

interface Props {
  onChange: (...event: any[]) => void;
  value?: string;
  name: string;
  id: string;
  selectedState?: string;
}

export default function CitySelect({
  onChange,
  value,
  name,
  id,
  selectedState,
}: Props) {
  const [inputItems, setInputItems] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [rawInputValue, setRawInputValue] = useState('');

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    // getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    onSelectedItemChange: ({ inputValue }) => onChange(inputValue),
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
      setRawInputValue(inputValue || '');
    },
  });

  function filterInputItems(inputValue: string) {
    setInputItems(
      cityStates
        .filter((cityState) => {
          const cityMatches = cityState.city
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());
          if (selectedState) {
            return (
              cityState.state.toLowerCase() === selectedState.toLowerCase() &&
              cityMatches
            );
          }
          return cityMatches;
        })
        .map((cityState) => cityState.city)
    );
    setLoading(false);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (rawInputValue) {
        filterInputItems(rawInputValue);
      } else {
        setInputItems([]);
        setLoading(false);
      }
    }, 500);

    setLoading(true);

    return () => clearTimeout(delayDebounceFn);
  }, [rawInputValue]);

  return (
    <div className="ds-combobox">
      <div
        // {...getComboboxProps()}
        // style={comboboxStyles}
        className="ds-combobox__input"
      >
        <label htmlFor={id} {...getLabelProps()}>
          <span className="visually-hidden">City</span>
          <input id={id} name={name} {...getInputProps()} placeholder="City" />
        </label>
        <button
          type="button"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
        >
          {/* &#8595; */}
        </button>
      </div>
      <ul
        {...getMenuProps()}
        // style={menuStyles}
        className={`ds-combobox__options ${isOpen ? 'is-open' : ''}`}
      >
        {isOpen &&
          !loading &&
          !!inputItems.length &&
          inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index
                  ? {
                      backgroundColor: 'var(--color-accent)',
                      color: 'var(--white)',
                    }
                  : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
        {isOpen && !loading && !inputItems.length && (
          <li>
            <em>Nothing found. </em>
          </li>
        )}
        {isOpen && loading && (
          <li className="loading">
            <span>Loading...</span>
            <motion.div
              initial={{ '--rotate': '0deg' } as any}
              animate={{ '--rotate': '360deg' } as any}
              transition={{ duration: 1, repeat: Infinity }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
                xmlSpace="preserve"
                style={{
                  height: '1.5rem',
                  width: '1.5rem',
                  transform: 'rotate(var(--rotate))',
                }}
              >
                <path
                  fill="currentColor"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                />
              </svg>
            </motion.div>
          </li>
        )}
      </ul>
    </div>
  );
}
