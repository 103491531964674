import React from 'react';

export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="320.43"
      height="31.857"
      fill="currentColor"
      viewBox="0 0 320.43 31.857"
      aria-labelledby="logo-title"
      role="img"
    >
      <title id="logo-title">The Halcyon Movement</title>
      <path d="M23.423 29.982v1.875h-3.748V17.801H3.746v14.055H0V0h3.746v14.055h15.929V0h3.748v29.982zM52.28 31.857h-4.075l1.124-2.624 12.18-28.104.515-1.124h2.437l.515 1.124 12.179 28.1 1.126 2.624h-4.077l-.517-1.124L63.24 6.602 52.796 30.729zM103.061 0h3.748v28.108h14.992v3.748h-18.74V0zM170.754 25.11a12.68 12.68 0 01-2.717 3.185 14.054 14.054 0 01-9.275 3.561 12.929 12.929 0 01-9.7-4.452 17.427 17.427 0 01.047-23 12.633 12.633 0 019.65-4.4 13.817 13.817 0 016.23 1.547 12.559 12.559 0 015.715 5.34l-3.372 1.687a7.8 7.8 0 00-1.734-2.2 10.1 10.1 0 00-6.839-2.624 9.4 9.4 0 00-6.886 3.139 12.829 12.829 0 00-3.373 9.041 13.3 13.3 0 002.671 8.2 9.217 9.217 0 007.589 3.981 9.967 9.967 0 003.841-.8 9.534 9.534 0 004.873-4.075zM207.624 29.982v1.875h-3.747V16.538l-8.713-13.633L193.336 0h4.449l.563.843 7.4 11.572L212.963.89l.562-.89h4.4l-1.781 2.858-8.52 13.585zM245.616 4.405a12.776 12.776 0 019.7-4.4 13.086 13.086 0 019.743 4.451 17.427 17.427 0 01-.047 23 12.777 12.777 0 01-9.7 4.4 13.077 13.077 0 01-9.745-4.452 17.427 17.427 0 01.047-23zm2.109 19.768a9.248 9.248 0 007.589 3.935 9.4 9.4 0 006.885-3.138 12.723 12.723 0 003.422-9.042 13.214 13.214 0 00-2.718-8.245 9.254 9.254 0 00-7.589-3.935 9.4 9.4 0 00-6.887 3.139 12.723 12.723 0 00-3.42 9.041 13.2 13.2 0 002.718 8.245zM300.752 31.857h-3.747V0h2.86l.562.8 16.207 23.229.047-22.158V-.004h3.748v1.875l-.047 28.107v1.875h-2.859l-.561-.8-16.21-23.235v24.033z" />
    </svg>
  );
}
