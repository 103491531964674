import type { GatsbyBrowser } from 'gatsby';
import { Layout as App } from '@halcyon/lib';
import Layout from './src/components/Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <App>
    <Layout {...props}>{element}</Layout>
  </App>
);
