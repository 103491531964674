import React, { useContext, useEffect, useState } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
// import { Link } from 'gatsby';
import { MdLock } from 'react-icons/md';
import { IoChevronDown } from 'react-icons/io5';
import Checkbox from './Checkbox';
import CitySelect from './CitySelect';
import FormSubmitButton from './FormSubmitButton';
import { SnackbarContext } from '../Snackbar';
import { states } from '../../utils/states';
import useScript from '../../hooks/useScript';

interface FormMessageTypes {
  status: string;
  message: string | React.ReactChild;
  open: boolean;
}

interface Props {
  intro?: string;
  incrementCount: () => void;
  branch?: string;
}

declare global {
  interface Window {
    twttr: any;
  }
}

function SuccessTwitterConversion() {
  const status = useScript(`https://static.ads-twitter.com/oct.js`);
  useEffect(() => {
    if (status === 'ready' && typeof window.twttr !== 'undefined') {
      window.twttr.conversion.trackPid(process.env.GATSBY_TWITTER_PIXEL_ID, {
        tw_sale_amount: 0,
        tw_order_quantity: 0,
      });
    }
  }, [status]);
  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src={`https://analytics.twitter.com/i/adsct?txn_id=${process.env.GATSBY_TWITTER_PIXEL_ID}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`}
      />
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src={`//t.co/i/adsct?txn_id=${process.env.GATSBY_TWITTER_PIXEL_ID}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`}
      />
    </noscript>
  );
}

function SuccessCatholicAdNetConversion() {
  useScript(`/catholic-ad-net.js`);
  return null;
}

export default function Form({ intro, incrementCount, branch }: Props) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const [formMessage, setFormMessage] = useState<FormMessageTypes>({
    status: '',
    message: '',
    open: false,
  });

  function handleResponse(response: Response) {
    if (response.ok) {
      incrementCount();
      setFormMessage({
        status: 'success',
        message: (
          <>
            <p>
              <strong>
                Thank you for signing and revealing both your courage and
                conviction.
              </strong>
            </p>
            <p>You're not alone. Don't ever forget this.</p>
            <p>The truth inevitably wins out. So, stand firm.</p>
          </>
        ),
        open: true,
      });
      reset(); // Clear form on success
    } else {
      setFormMessage({
        status: 'error',
        message: `Sorry, there's an error in submitting your subscription. Please try again later.`,
        open: true,
      });
    }
  }

  // Netlify Forms Functions
  // function encode(data: FieldValues) {
  //   return Object.keys(data)
  //     .map(
  //       (key) =>
  //         `${encodeURIComponent(key)}=${encodeURIComponent(data[key] || '')}`
  //     )
  //     .join('&');
  // }

  const onSubmit: SubmitHandler<FieldValues> = (data, e) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        'On Dev environment, no data will be submitted but will be logged instead.'
      );
      console.log(data);
      handleResponse({ ok: true } as Response);
      return;
    }
    setLoading(true);
    fetch('/.netlify/functions/submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        formName: e?.target.getAttribute('name'),
        ...data,
      }),
    })
      .then((response) => handleResponse(response))
      .catch((error) => alert(error))
      .then(() => setLoading(false));
  };

  const { addSnackbar, removeSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (formMessage.open) {
      if (formMessage.status === 'error') {
        addSnackbar(formMessage.message, formMessage.status);
      }
    } else {
      removeSnackbar();
    }
  }, [formMessage, addSnackbar, removeSnackbar]);

  const selectedState = watch('state');

  // Thank You Message
  if (formMessage.open && formMessage.status === 'success') {
    return (
      <>
        <div id={branch || 'form-success'} style={{ marginTop: '1.5rem' }}>
          {formMessage.message}
        </div>
        {process.env.GATSBY_TWITTER_PIXEL_ID && <SuccessTwitterConversion />}
        {process.env.GATSBY_CATHOLICADNET_CONVERSION && (
          <SuccessCatholicAdNetConversion />
        )}
      </>
    );
  }

  return (
    <>
      <p>{intro}</p>
      <form
        name="petition"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        aria-busy={!!loading}
        autoComplete="on"
        className={branch}
      >
        <div className="form-fields">
          <div className="form-field form-field--half">
            <label htmlFor="petitioner-name">
              <input
                type="text"
                id="petitioner-name"
                placeholder="First Name"
                {...register('firstName', {
                  required: true,
                })}
                aria-invalid={!!errors.firstName}
              />
              <span className="visually-hidden">First Name</span>
            </label>
          </div>
          <div className="form-field form-field--half">
            <label htmlFor="petitioner-email">
              <input
                type="email"
                id="petitioner-email"
                placeholder="Email"
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,
                })}
                aria-invalid={!!errors.email}
              />
              <span className="visually-hidden">Email</span>
            </label>
          </div>
          <div className="form-field form-field--half form-field--mobile-full">
            <label htmlFor="petitioner-state" className="select-wrapper">
              <span className="visually-hidden">State</span>
              <select
                id="petitioner-state"
                placeholder="State"
                {...register('state', {
                  required: true,
                })}
                aria-invalid={!!errors.state}
              >
                <option value="">State</option>
                {states.map((state, i) => (
                  <option key={`state-${i}`} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <IoChevronDown />
            </label>
          </div>
          <div className="form-field form-field--half form-field--mobile-full">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="petitioner-city">
              <span className="visually-hidden">City</span>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <CitySelect
                    value={value}
                    onChange={onChange}
                    name="city"
                    id="petitioner-city"
                    selectedState={selectedState}
                  />
                )}
                control={control}
                name="city"
              />
            </label>
          </div>
          <div className="form-field form-field--half form-field--comments">
            <label htmlFor="petitioner-comments">
              <textarea
                id="petitioner-comments"
                placeholder="Comments"
                {...register('comments')}
                aria-invalid={!!errors.comments}
              />
              <span className="visually-hidden">Comments</span>
            </label>
          </div>
          <div className="form-field form-field--half form-field--checkboxes-wrapper">
            <ul className="form-field--checkboxes">
              <li>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id="petitioner-subscribe"
                      name="subscribe"
                      value={value}
                      onChange={onChange}
                      invalid={!!errors.subscribe}
                    >
                      Keep me informed on this petition and other similar issues
                      by subscribing to Halcyon’s newsletter.
                    </Checkbox>
                  )}
                  control={control}
                  name="subscribe"
                />
              </li>
              <li>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id="petitioner-consent"
                      name="consent"
                      value={value}
                      onChange={onChange}
                      invalid={!!errors.consent}
                    >
                      I agree to the{' '}
                      <a
                        href="https://thehalcyonmovement.org/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://thehalcyonmovement.org/cookie-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cookie Policy.
                      </a>
                    </Checkbox>
                  )}
                  control={control}
                  name="consent"
                  rules={{ required: true }}
                />
              </li>
            </ul>
          </div>
          <div style={{ display: 'none' }}>
            <label htmlFor="organization">
              Don’t fill this out if you’re human:{' '}
              <input
                type="text"
                autoComplete="false"
                tabIndex={-1}
                {...register('organization')}
              />
            </label>
          </div>
          <div className="form-field form-field--submit">
            <FormSubmitButton
              loading={loading}
              text={
                <>
                  <MdLock /> Sign Petition
                </>
              }
            />
          </div>
        </div>
      </form>
    </>
  );
}
