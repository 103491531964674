import { PetitionCount } from '../PetitionCount';
import { Form } from '../Form';
import './PetitionSection.scss';
import type { PetitionSectionProps } from './PetitionSection.types';
import useCounter from '../../hooks/useCounter';

export function PetitionFormBox({ branch }: { branch?: string }) {
  const { count, target, incrementCount } = useCounter();

  return (
    <div className="petition__card">
      <PetitionCount count={count} target={target} />
      <div className="petition__form">
        <Form incrementCount={incrementCount} branch={branch} />
      </div>
    </div>
  );
}

export default function PetitionSection({
  title,
  subtitle,
  text,
  showTextFooter,
  branch,
}: PetitionSectionProps) {
  return (
    <section id="petition" className="container petition section-m-b">
      <div className="row">
        <div className="col-xs-12 col-sm-10 col-md-6 col-lg-5 col-lg-offset-1">
          <div className="petition__text-wrapper">
            <div className="petition__text">
              {title && <h2 className="h5">{title}</h2>}
              {subtitle && <h3 className="h6">{subtitle}</h3>}
              {text}
            </div>
            {showTextFooter && (
              <div className="petition__text-footer hide-xs show-md">
                <h3 className="h3">Still on the fence? See below!</h3>
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <PetitionFormBox branch={branch} />
        </div>
      </div>
    </section>
  );
}
