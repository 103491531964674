import * as React from 'react';

const TARGET_STEPS = [1000, 5000, 10000, 50000];

export default function useCounter() {
  const [count, setCount] = React.useState(0);
  const [target, setTarget] = React.useState(TARGET_STEPS[0]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  React.useEffect(() => {
    fetch(`/.netlify/functions/getPetitionCount`)
      .then((response) => response.json())
      .then((response) => {
        const { total } = response;
        setCount(total);
        const nextTarget = TARGET_STEPS.find((t) => t > total);
        setTarget(nextTarget || TARGET_STEPS[0]);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return { count, target, incrementCount };
}
