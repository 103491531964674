import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './PetitionCount.scss';

export default function PetitionCount({
  count,
  target,
  text,
}: {
  count: number;
  target: number;
  text?: string;
}) {
  const controls = useAnimation();

  useEffect(() => {
    if (!count) {
      return;
    }
    controls.set({ color: 'var(--color-accent)' });
    controls.start({
      scale: 1,
      transition: {
        type: 'spring',
        velocity: 50,
        stiffness: 700,
        damping: 80,
      },
    });
    controls.start({
      color: 'var(--color-p)',
      transition: {
        duration: 3,
      },
    });
  }, [count]);

  return (
    <div className="petition-meter">
      <h3 className="h6">
        <motion.span animate={controls} style={{ display: 'inline-block' }}>
          <strong>{count.toLocaleString()}</strong>
        </motion.span>{' '}
        {text || 'have signed'}. Let’s get to {target.toLocaleString()}!
      </h3>
      <div className="meter" aria-hidden="true">
        <div className="meter__bg" />
        <div
          className="meter__filled"
          style={{ width: `${(count / target) * 100}%` }}
        />
      </div>
    </div>
  );
}
